//Show the card button if there are no parent or children
import { actionsBtnCallback, cardBtnCallback } from "./cardButtonCallbacks";

async function cardButtons(t, options) {
  const parent = await t.get("card", "shared", "parent");
  const children = await t.get("card", "shared", "children");
  const setting = await t
    .get("board", "shared", "settings")
    .then((settings) =>
      settings === undefined ? true : settings.showHierarchyOnCardBack
    );

  if (!setting) {
    // Hide hierarchy management buttons if showHierarchyOnCardBack is false
    return [
      {
        icon: "https://lh3.googleusercontent.com/d/1Zn4mXdn0ulDcYXBFHhIsSkOUdS7deRny",
        text: "Hierarchy",
        condition: "edit",
        callback: function (t) {
          cardBtnCallback(t);
        },
      },
    ];
  } else {
    return [
      {
        icon: "https://lh3.googleusercontent.com/d/1Zn4mXdn0ulDcYXBFHhIsSkOUdS7deRny",
        text: "Hierarchy",
        condition: "edit",
        callback: function (t) {
          actionsBtnCallback(t, parent, "Hierarchy");
        },
      },
    ];
  }
}

export default cardButtons;
