function onEnable(t, opts) {
  return t.modal({
    title: "",
    accentColor: "#1064E3",
    url: "./on-enable.html",
    height: 593,
  });
}

export default onEnable;
