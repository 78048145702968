import { getBoardBackground } from "../services/api/getBoardBackground";

async function openModal(t, cardId) {
  const context = t.getContext();
  const theme = context.initialTheme;
  const boardId = context.board;
  const background = await getBoardBackground(t, boardId);

  t.modal({
    title: process.env.POWERUP_NAME,
    url: "./modal.html",
    accentColor: theme === "dark" ? "" : background ? background.topColor : "",
    fullscreen: true,
    args: { cardId: cardId },
  });
}

export default openModal;
