import cardBackSection from "./cardBackSection";
import boardButtons from "./boardButtons";
import cardButtons from "./cardButtons";
import showSettings from "./showSettings";
import onEnable from "./onEnable";

window.TrelloPowerUp.initialize(
  {
    "card-back-section": cardBackSection,
    "board-buttons": boardButtons,
    "card-buttons": cardButtons,
    "show-settings": showSettings,
    "on-enable": onEnable,
  },
  {
    appKey: process.env.POWERUP_KEY,
    appName: "Hierarchy for Trello",
    appAuthor: "Native Power-Ups",
  }
);
