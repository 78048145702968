async function searchAndSaveParent(t, opts) {
  // Fetch the ID of the currently open card
  const openCard = await t.card("id");
  const openCardId = openCard.id;

  // Fetch the existing parent of the open card, if there is one
  const existingParent = (await t.get("card", "shared", "parent")) || null;

  // Recursive function to fetch all descendants of the open card
  async function fetchDescendants(cardId, descendantsArr = []) {
    const childrenIds = (await t.get(cardId, "shared", "children")) || [];
    descendantsArr.push(...childrenIds);

    for (const childId of childrenIds) {
      await fetchDescendants(childId, descendantsArr); // Recurse into children
    }

    return descendantsArr;
  }

  const openCardDescendants = await fetchDescendants(openCardId);

  const cardsToHideFromSearch = [
    openCardId,
    existingParent,
    ...openCardDescendants,
  ];

  // Fetch all cards and filter out the currently open card and its parent
  const allCards = await t.cards("id", "name", "dateLastActivity");

  const sortByDateLastActivity = (arr) => {
    return arr.sort(
      (a, b) => new Date(b.dateLastActivity) - new Date(a.dateLastActivity)
    );
  };

  const sortedCards = sortByDateLastActivity(allCards);

  function removeIdsFromList(largerList, idsToRemove) {
    return largerList.filter((card) => !idsToRemove.includes(card.id));
  }

  // Filter out the open card and its children from the list of all cards
  const filteredCards = removeIdsFromList(sortedCards, cardsToHideFromSearch);

  // Transform the card data for use in a search popup
  const selectOptions = filteredCards.map((selectedCard) => ({
    text: selectedCard.name,
    // Open confirm popup
    callback: async function (t, opts) {
      if (!existingParent) {
        // Save parent using the selectedCard prop
        const parentId = selectedCard.id;

        // Set parent against open card
        await t.set("card", "shared", "parent", parentId);

        // Get parent's existing children and create an array with the new child
        const newChildrenArr = await t
          .get(parentId, "shared", "children")
          .then((existingChildren) => {
            // Initialize to an empty array if undefined
            if (!existingChildren) {
              existingChildren = [];
            }
            const updatedChildren = existingChildren.concat(openCardId);
            return updatedChildren;
          });
        // Set new children arr against parent card
        await t.set(parentId, "shared", "children", newChildrenArr);

        // Close the popup after saving
        await t
          .closePopup()
          .catch((error) => console.error("Error closing popup:", error));
      } else {
        return t.popup({
          type: "confirm",
          title: "Change parent",
          message:
            "Are you certain that you want to change the parent of this card?",
          confirmText: "Change parent",
          confirmStyle: "danger",
          // Save parent using the card prop
          onConfirm: async function (t, opts) {
            const parentId = selectedCard.id;

            // Set parent against open card
            await t.set("card", "shared", "parent", parentId);

            // Get parent's existing children and create an array with the new child
            const newChildrenArr = await t
              .get(parentId, "shared", "children")
              .then((existingChildren) => {
                // Initialize to an empty array if undefined
                if (!existingChildren) {
                  existingChildren = [];
                }
                const updatedChildren = existingChildren.concat(openCardId);
                return updatedChildren;
              });
            // Set new children arr against parent card
            await t.set(parentId, "shared", "children", newChildrenArr);

            // Remove open card as child from existing parent
            const existingParentChildren = await t
              .get(existingParent, "shared", "children")
              .then((childrenArr) => childrenArr);
            await t.set(
              existingParent,
              "shared",
              "children",
              existingParentChildren.filter((item) => item !== openCardId)
            );

            // Close the popup after saving
            await t
              .closePopup()
              .catch((error) => console.error("Error closing popup:", error));
          },
        });
      }
    },
  }));

  return t.popup({
    title: existingParent ? "Change parent" : "Add a parent",
    items: selectOptions,
    search: {
      count: 10, // number of items to display at a time
      placeholder: "Search...",
      empty:
        "No search results found. Please note that search results do not include the open card's decendents.",
    },
  });
}

export default searchAndSaveParent;
