import openModal from "./openModal";
import searchAndSaveParent from "../utils/searchAndSaveParent";
import searchAndSaveChild from "../utils/searchAndSaveChild";
import searchAndRemoveRelation from "../utils/searchAndRemoveRelation";

export async function actionsBtnCallback(t, existingParent, title) {
  return t.popup({
    title: title,
    items: [
      {
        text: existingParent ? "Change parent" : "Add a parent",
        callback: searchAndSaveParent,
      },
      {
        text: "Add a child",
        callback: async function (t) {
          const openCardId = await t.card("id").then((card) => card.id);
          return searchAndSaveChild(t, openCardId);
        },
      },
      {
        text: "Delete a relationship",
        callback: searchAndRemoveRelation,
      },
      {
        text: "Open Hierarchy",
        callback: async function (t, opts) {
          const cardId = await t.card("id").then((card) => card.id);
          openModal(t, cardId);
        },
      },
      {
        text: "Settings",
        callback: function (t, opts) {
          return t.popup({
            title: "Settings",
            url: "./settings.html",
          });
        },
      },
    ],
  });
}

export async function cardBtnCallback(t, opts) {
  return t.popup({
    title: "Hierarchy",
    items: [
      {
        text: "Open Hierarchy",
        callback: function (t) {
          openModal(t, null);
        },
      },
      {
        text: "Settings",
        callback: function (t, opts) {
          return t.popup({
            title: "Settings",
            url: "./settings.html",
          });
        },
      },
    ],
  });
}
