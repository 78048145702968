const searchAndSaveChild = async function (t, cardId, refreshModalHierarchies) {
  // Fetch the ID of the currently open card
  const openCardId = cardId;

  // Fetch all cards and filter out the currently open card and it's children
  const allCards = await t.cards("id", "name", "dateLastActivity");

  const sortByDateLastActivity = (arr) => {
    return arr.sort(
      (a, b) => new Date(b.dateLastActivity) - new Date(a.dateLastActivity)
    );
  };

  const sortedCards = sortByDateLastActivity(allCards);

  // Fetch parent of the open card and initialize to an empty array if undefined
  const openCardParent = (await t.get(cardId, "shared", "parent")) || null;

  // Fetch children of the open card and initialize to an empty array if undefined
  const openCardChildren = (await t.get(cardId, "shared", "children")) || [];

  // Fetch siblings of the open card and initialize to an empty array if undefined
  async function getSiblings(card) {
    if (card && card.length > 0) {
      const openCardSiblings = (await t.get(card, "shared", "children")) || [];
      return openCardSiblings;
    } else {
      const openCardSiblings = [];
      return openCardSiblings;
    }
  }
  const openCardSiblings = await getSiblings(openCardParent);

  // Fetch ancestors of the open card and initialize to an empty array if undefined
  async function getAncestors(cardId, ancestorsArr = []) {
    // Ensure cardId is valid and not an empty array
    if (!cardId || cardId.length === 0) {
      return ancestorsArr;
    }
    const parentId = await t.get(cardId, "shared", "parent");
    if (parentId) {
      ancestorsArr.push(parentId);
      return getAncestors(parentId, ancestorsArr); // Recurse if there is a parent
    }
    return ancestorsArr;
  }

  const openCardAncestors = await getAncestors(openCardParent);

  const cardsToHideFromSearch = [
    ...openCardChildren,
    ...openCardSiblings,
    ...openCardAncestors,
    openCardParent,
    openCardId,
  ];

  function removeIdsFromList(largerList, idsToRemove) {
    return largerList.filter((card) => !idsToRemove.includes(card.id));
  }

  // Filter out the open card and its children from the list of all cards
  const filteredCards = removeIdsFromList(sortedCards, cardsToHideFromSearch);

  // Transform the card data for use in a search popup
  const selectOptions = filteredCards.map((selectedCard) => ({
    text: selectedCard.name,
    // Open confirm popup
    callback: async function (t, opts) {
      // Find if the selected card already has a parent
      const selectedCardParent = await t.get(
        selectedCard.id,
        "shared",
        "parent"
      );

      if (!selectedCardParent) {
        const childrenArr = [...openCardChildren, selectedCard.id];

        // Set child against open card
        await t.set(cardId, "shared", "children", childrenArr);

        // Set open card as parent against child card
        await t.set(selectedCard.id, "shared", "parent", openCardId);

        await t.closePopup();

        if (refreshModalHierarchies) {
          refreshModalHierarchies();
        }
      } else {
        return t.popup({
          type: "confirm",
          title: "Add a child",
          message:
            "Adding the selected card as a child will change its parent to this card. Are you sure you want to proceed?",
          confirmText: "Add child",
          confirmStyle: "danger",
          // Save child using the card prop
          onConfirm: async function (t, opts) {
            const childrenArr = [...openCardChildren, selectedCard.id];

            // Set child against open card
            await t.set(cardId, "shared", "children", childrenArr);

            // Set open card as parent against child card
            await t.set(selectedCard.id, "shared", "parent", openCardId);

            // Remove the selectedCard from the existing parent
            const selectedCardChildren = await t.get(
              selectedCardParent,
              "shared",
              "children"
            );

            const newSelectedCardChildren = selectedCardChildren.filter(
              (card) => card !== selectedCard.id
            );

            // Set new children array for the old parent
            await t.set(
              selectedCardParent,
              "shared",
              "children",
              newSelectedCardChildren
            );

            await t.closePopup();

            if (refreshModalHierarchies) {
              refreshModalHierarchies();
            }
          },
        });
      }
    },
  }));

  return t.popup({
    title: "Add a child",
    items: selectOptions,
    search: {
      count: 10, // number of items to display at a time
      placeholder: "Search...",
      empty:
        "No search results found. Please note that search results do not include the open card's children, parent, grandparent, and ancestors.",
    },
  });
};

export default searchAndSaveChild;
