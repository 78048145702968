const apiKey = process.env.POWERUP_KEY;

export async function getBoardBackground(t, boardId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/boards/${boardId}?fields=prefs&key=${apiKey}&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.prefs) {
          return {
            url: data.prefs.backgroundImage,
            color: data.prefs.backgroundColor,
            topColor: data.prefs.backgroundTopColor,
            brightness: data.prefs.backgroundBrightness,
          };
        }
        return null;
      })
      .catch((error) => {
        console.error("Failed to fetch Trello board background:", error);
        return null;
      });
  }
}
