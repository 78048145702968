import openModal from "./openModal";

function boardButtons(t, opts) {
  return [
    {
      icon: "https://lh3.googleusercontent.com/d/1Zn4mXdn0ulDcYXBFHhIsSkOUdS7deRny",
      text: process.env.POWERUP_NAME,
      condition: "edit",
      callback: function (t) {
        openModal(t, null);
      },
    },
  ];
}

export default boardButtons;
