async function searchAndRemoveRelation(t, opts) {
  // Fetch the ID of the currently open card
  const openCardId = await t.card("id").then((card) => card.id);

  // Fetch parent of the open card and initialize to an empty array if undefined
  const openCardParentId = (await t.get("card", "shared", "parent")) || null;

  // Fetch children of the open card and initialize to an empty array if undefined
  const openCardChildrenIds = (await t.get("card", "shared", "children")) || [];

  const removeOptionIds = [openCardParentId, ...openCardChildrenIds].filter(
    (id) => id
  );

  // Helper function to fetch card details
  async function fetchCardDetails(cardId) {
    const cards = await t.cards("id", "name");
    return cards.find((card) => card.id === cardId);
  }

  // Create an array of promises
  const removeOptionDetailsPromises = removeOptionIds.map((id) =>
    fetchCardDetails(id)
  );

  // Use Promise.all to wait for all promises to resolve
  const removeOptionDetails = await Promise.all(removeOptionDetailsPromises);

  const items = removeOptionDetails.map((selectedCard) => ({
    text: selectedCard.name,
    // Open confirm popup
    callback: async function (t, opts) {
      return t.popup({
        type: "confirm",
        title: "Delete a relationship",
        message: "Are you certain that you want to delete this relationship?",
        confirmText: "Delete",
        confirmStyle: "danger",
        onConfirm: async function (t, opts) {
          if (selectedCard.id === openCardParentId) {
            // If the card to remove is the parent of the open card, remove the parent from the open card
            await t.remove("card", "shared", "parent");
            // and remove the open card as a child from the parent card
            const selectedCardChildren = await t.get(
              selectedCard.id,
              "shared",
              "children"
            );
            const newSelectedCardChildren = selectedCardChildren.filter(
              function (item) {
                return item !== openCardId;
              }
            );
            await t.set(
              selectedCard.id,
              "shared",
              "children",
              newSelectedCardChildren
            );
          } else {
            // Else, the card to remove is a child of the open card. Remove the child from the open card, and the parent from the child card
            const newOpenCardChildrenIds = openCardChildrenIds.filter(function (
              item
            ) {
              return item !== selectedCard.id;
            });
            await t.set("card", "shared", "children", newOpenCardChildrenIds);
            await t.remove(selectedCard.id, "shared", "parent");
          }

          await t.closePopup();
        },
      });
    },
  }));

  return t.popup({
    title: "Delete a relationship",
    items: items,
    search: {
      count: 10, // number of items to display at a time
      placeholder: "Search...",
      empty: "There are no related cards",
    },
  });
}

export default searchAndRemoveRelation;
