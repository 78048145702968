//Show the card button if there is a parent or children
import { actionsBtnCallback } from "./cardButtonCallbacks";

async function cardBackSection(t, options) {
  const parent = await t.get("card", "shared", "parent");
  const children = await t.get("card", "shared", "children");
  const setting = await t
    .get("board", "shared", "settings")
    .then((settings) =>
      settings === undefined ? true : settings.showHierarchyOnCardBack
    );

  if (setting && (parent || (children && children.length > 0))) {
    return {
      title: "Hierarchy",
      icon: "https://lh3.googleusercontent.com/d/1Zn4mXdn0ulDcYXBFHhIsSkOUdS7deRny",
      content: {
        type: "iframe",
        url: t.signUrl("./card-back.html"),
      },
      action: {
        text: "Actions",
        callback: function (t) {
          actionsBtnCallback(t, parent, "Actions");
        },
      },
    };
  } else {
    return null;
  }
}

export default cardBackSection;
